<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "tiaozhandianduihuan",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到挑战点兑换幸运币页",
                            "#按图1说明参数:",
                            "1.必填项-兑换:输入要兑换的次数",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#打开闯荡四海,杂物回收和背包界面三个界面(图1)",
                            '以上设置好,开始任务',
                        ],
                        img:[
                            "2.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            setTimeout(res =>{
                this.$refs.row.initData(this.list);
                console.log(this.$options.name);
            },100)
        }
    }
</script>

<style scoped>

</style>
